import React, { useState, useContext, useEffect } from "react";
import { AlertContext } from "../../Context/AlertContextProvider";

// Icons
import * as ImIcons from 'react-icons/im';

// MUI
import { Button, Paper, 
  TextField,
  Grid, 
  Dialog, 
  Typography,
  Tooltip,
  Select,
  MenuItem
} from "@material-ui/core";

// Style
  import "../styles/NewSite.css";
  
// Manual uploads
import { createNewSite, createS3, createEc2, describeEc2Instances, describeDbInstances, createDbInstance, getExternalSoftwareList, getAffiliateGroupList, getCompanies } from "../../Actions"
import { Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const NewSite = () => {
    const [newSiteName, setNewSiteName] = useState("");
    const [newS3, setNewS3] = useState("");
    const [newEc2, setNewEc2] = useState("");
    const { handleAlertOpen, setMessageType, setMessage } = useContext(
      AlertContext
    );
    const [showProgress, setShowProgress] = useState(false);
    const [open, setOpen] = useState(false);
    const [dbInstances, setDbInstances] = useState([]);
    const newDbInstanceDisclaimer = "A new database instance needs to be created, would you like to do that now? It will take approximately 10 minutes to initialize the new instance.";
    const creatingDbInstance = "A new database instance is being created. This will take approximately 10 minutes. This message will disapear when finished. DO NOT REFRESH THIS";
    const [dialogText, setDialogText] = useState("");
    const [dbInstanceDisclaimer, setDbInstanceDisclaimer] = useState(newDbInstanceDisclaimer);
    const [visible, setVisible] = useState("block");
    const [disabled, setDisabled] = useState(true);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogType, setDialogType] = useState();
    const [validationError, setValidationError] = useState("");
    const [externalSoftwareList, setExternalSoftwareList] = useState([]);
    const [selectedExtSoftware, setSelectedExtSoftware] = useState("");
    const [affiliateGroupList, setAffiliateGroupList] = useState([]);
    const [companyFilter, setCompanyFilter] = useState("");
    const [selectedCompany, setSelectedCompany] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("");
    const [selectedSiteType, setSelectedSiteType] = useState("0");
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
      function getDropwdownData() {
        getExternalSoftwareList()
          .then((result) => {
            setExternalSoftwareList(result.data.data);
          })
          .catch((err) => {
            setExternalSoftwareList([]);
          });
  
        getAffiliateGroupList()
          .then((result) => {
            setAffiliateGroupList(result.data.data);
          })
          .catch((err) => {
            setAffiliateGroupList([]);
          });

        getCompanies()
          .then((result) => {
            setCompanyList(result.data.data);
          })
          .catch((err) => {
            setCompanyList([]);
          });
      }
  
      if (refresh) {
        getDropwdownData();
        setRefresh(false);
      }
    }, [refresh]);

    useEffect(() => {
      if (selectedSiteType === "") {
        setCompanyFilter("");
        setSelectedCompany(undefined);
        setSelectedExtSoftware("");
        setSelectedAffiliateGroup("");

        return;
      }

      if (!selectedCompany) {
        setSelectedExtSoftware("");
        setSelectedAffiliateGroup("");

        return;
      }

      if (selectedCompany?.external_software_id) setSelectedExtSoftware(selectedCompany.external_software_id);
      if (selectedCompany?.affiliate_group_id) setSelectedAffiliateGroup(selectedCompany.affiliate_group_id);
    }, [selectedCompany, selectedSiteType])

    const handleSuccess = () => {
      window.location.reload();
      window.open("https://console.cloud.google.com/apis/credentials?project=avian-hangout-303102");
    }

    const handleOpen = () => {
      setOpen(true);
    }

    const handleNewDbInstance = () => {
      setDbInstanceDisclaimer(creatingDbInstance)
      setShowProgress(true);
      setVisible("none");
      createDbInstance(`sp-${dbInstances.length}`).then(() => {
        describeDbInstances()
        .then((result) => {
          var DBInstanceIdentifier;
          result.data.data.DBInstances.map((i, index) => {
            if (i.DBInstanceStatus === "creating") {
              DBInstanceIdentifier= i.DBInstanceIdentifier
            }
          })
          return DBInstanceIdentifier
        })
        .then((DBInstanceIdentifier) => {
          var tempInstanceStatus = "";
          let timer = setInterval(() => {
            if (tempInstanceStatus != "available") {
            describeDbInstances(DBInstanceIdentifier)
            .then((result) => {
              tempInstanceStatus = result.data.data.DBInstances[0].DBInstanceStatus
            })
            console.log(tempInstanceStatus)
          } else {
            console.log("All donzies!")
            clearInterval(timer);
            handleSuccess();
            setOpen(false);
            createNewSite(newSiteName);
          }
          }, [5 * 1000])
        })
      })
    };

    const handleSiteNameChange = (event) => {
      const siteName = event.target.value;

      if(/(https|http|www\.|\.com|[^A-z0-9-]+)/g.test(siteName)) {
        setDisabled(true);
        setValidationError(`Site Name can't contain "http(s)", "www.", ".com", or anything outside of allowed character list (A-Z, 0-9, -).`)
        return;
      } 

      setValidationError("");
      setDisabled(false);
      setNewSiteName(siteName);  
    };

    const handleS3Change = (event) => {
        setNewS3(event.target.value);
      };

    const handleEc2Change = (event) => {
      setNewEc2(event.target.value);
    }

    const handleSubmit = () => {
      setShowProgress(true);
      describeDbInstances()
      .then((result) => {
        setDbInstances(result.data.data.DBInstances)
      });

      createNewSite(
        newSiteName, 
        selectedSiteType === "0" ? 0 : null, 
        selectedExtSoftware, 
        selectedAffiliateGroup, 
        selectedCompany?.lead_id, 
        selectedCompany?.company_id
      ).then((result) => {
        if (result.statusText == "OK"){
          const message = `${newSiteName}'s site has been created!`;
          setMessageType("success");
          setMessage(message);
          handleAlertOpen();
          setShowProgress(false);
          setOpen(true);
          setDialogText(message);
          setDialogTitle("Site Created Successfully");
          setDialogType("success");
          setNewSiteName("");
          setSelectedExtSoftware("");
          setSelectedAffiliateGroup("");
          setSelectedSiteType("0");
        } 
      })
      .catch((err) => {
        const message = "Failed to create new schema!";
        setMessageType("error");
        setMessage(message);
        handleAlertOpen();
        handleOpen();
        setShowProgress(false);
        setDialogText(`${err.response.data.message}`);
        setDialogTitle("Error Creating Site");
        setDialogType("error");
      });
    };

    const handleNewS3 = () => {
        createS3(newS3)
        .then((result) => {
          if (result.statusText == "OK"){
            const message = "S3 bucket created";
          setMessageType("success");
          setMessage(message);
          handleAlertOpen();
          }
        })
        .catch((err) => {
          const message = "Failed to create new S3 bucket!";
          setMessageType("error");
          setMessage(message);
          handleAlertOpen();
          console.log(err);
        });
      };

      const handleNewEc2 = () => {
        createEc2(newEc2)
        .then((result) => {
          if (result.statusText == "OK"){
            console.log("Ec2 Instance created")
          }
        })
        .catch((err) => {
          console.log(err);
        });
      };

      const handleDescribeEc2 = () => {
        var tags = [];
        var parsedTags = [];
        describeEc2Instances()
        .then((result) => {
          result.data.data.Reservations.forEach((instances,i) => {tags.push(instances.Instances[0].Tags)})
          tags.forEach((tag) => {tag.forEach((ind) => {parsedTags.push(Object.values(ind))})})
          // parsedTags.map((key) => {if(key[0] === "Name"){console.log(key[1])}})

          // console.log(result.data.data.Reservations[1].Instances[0].Tags[3].Value)
          // console.log(result.data.data.Reservations[1].Instances[0].State.Name)
        })
        .catch((err) => {
          console.log(err)
        });
      };

      const renderDialog = () => {
        return (
          <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Paper style={{padding: "25px 20px 15px"}}>
            <Typography variant="h6" style={{marginBottom: "15px", color: dialogType == "success" ? "#4caf50" : "#f44336"}}><b>{dialogTitle}</b></Typography>
            <Typography>{dialogText}</Typography>
            { dialogType == "error" ? <Typography style={{marginTop: "20px"}}>Please contact the dev team if you need more information about this error.</Typography> : undefined}
            <div style={{textAlign: "right"}}>
              <Button 
                autoFocus 
                onClick={() => {
                  if (dialogType == "success") handleSuccess();
                  setOpen(false);
                }}
                variant="contained"
                style={{margin: "30px 0px 10px 20px"}}
              >
                Okay
              </Button>
            </div>
          </Paper>
        </Dialog>
        )
      }

      const isFuzzyMatch = (filter, value) => {
        if (!filter) return true;
    
        const regex = new RegExp(`${filter}`, "gi");
        
        if (value && regex.test(value)) return true;
        
        return false;
      }

      // const renderNewDbInstance = () => {
      //   return (
      //     <Dialog
      //     open={open}
      //     onClose={handleClose}
      //     aria-labelledby="alert-dialog-title"
      //     aria-describedby="alert-dialog-description"
      //   >
      //     <DialogContent>
      //       <DialogTitle>
      //         Status
      //       </DialogTitle>
      //       <DialogContentText id="alert-dialog-description">
      //         {dbInstanceDisclaimer}
      //       </DialogContentText>
      //     </DialogContent>
      //     {showProgress && <LinearProgress color="primary" size={200} thickness={5}/>}
      //     <DialogActions>
      //     <Button style={{display: visible}} autoFocus onClick={handleClose}>No</Button>
      //     <Button style={{display: visible }} onClick={handleNewDbInstance}>Yes</Button>
      //     </DialogActions>
      //   </Dialog>
      //   )
      // }

    return (
    <div className="newsite-container">
      {/* {renderNewDbInstance()} */}
          <Paper 
            elevation={3}
            className="create-site-paper"
          >
            <div className="header-paper">
              <Typography className="title" variant="h4">
                New Site
              </Typography>
            </div>
            <Grid container spacing={3} style={{padding: "30px 20px 20px"}}>
              <Grid item xs={12}>
                <Typography>
                  Enter Site Name *  
                  <Tooltip 
                    title={
                      <Typography style={{display: "inline-block", padding: "10px"}}>Allowed characters: A-Z, 0-9, -</Typography>
                    } 
                    placement="top" 
                    style={{display: "inline-block", marginLeft: "5px", verticalAlign: "middle"}}
                  >
                    <Info fontSize="small"/>
                  </Tooltip>    
                </Typography>
                <TextField
                  inputProps={{min: 0, style: {textAlign: "right", color: "#001BA5", width: "250px", padding: "18px 10px 15px"}}}
                  variant="filled"
                  error={!!validationError}
                  onChange={handleSiteNameChange}
                  style={{display: "inline-block", verticalAlign: "middle"}}
                />
                <Typography
                  className="url-label"
                  style={{display: "inline-block", verticalAlign: "middle", marginLeft: "5px"}}
                >
                  .showroompricing.com
                </Typography>
                { validationError ? (
                  <Typography style={{color: "red", width: "60%", margin: "auto"}}>{validationError}</Typography>
                ) : undefined }
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Site Type
                  <Tooltip 
                    title={
                      <Typography style={{display: "inline-block", padding: "10px"}}>This decides if the subscription should be set to active or not. (i.e. Customer = Active)</Typography>
                    } 
                    placement="top" 
                    style={{display: "inline-block", marginLeft: "5px", verticalAlign: "middle"}}
                  >
                    <Info fontSize="small"/>
                  </Tooltip> 
                </Typography>
                <Select
                  value={selectedSiteType}
                  onChange={(e) => {
                    setSelectedSiteType(e.target.value);
                  }}
                  variant="filled"
                  displayEmpty
                  fullWidth
                  style={{maxWidth: "200px"}}
                >
                  <MenuItem value={""}>
                    Testing
                  </MenuItem>
                  <MenuItem value={"0"}>
                    Customer
                  </MenuItem>
                </Select>
              </Grid>
              
              { selectedSiteType === "0" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography style={{marginTop: "25px"}}>If a lead for this company is available, please choose it in the dropdown below to connect them.</Typography>
                      <Typography style={{marginTop: "10px"}}>If you do not find it listed, then the company has most likely already been connected to a site.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Lead Company</Typography>
                      <Autocomplete
                        inputValue={companyFilter}
                        options={companyList}
                        getOptionLabel={(option) => option?.company ?? ""}
                        renderOption={(option) => (
                          <span key={option.company_id} id={option.company_id} style={{width: "100%"}}>
                            <Typography variant="body2"><b>{option.company}</b></Typography>
                            <Typography variant="body2">{option.city + ", " + option.state}</Typography>
                          </span>
                        )}
                        filterOptions={(options) => {
                          return options.filter((company) => company.company_id === selectedCompany?.company_id || isFuzzyMatch(companyFilter, company?.company))
                        }}
                        renderInput={(params) => <TextField {...params} label="Name" variant="filled" inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password'
                        }}/>}
                        fullWidth
                        style={{maxWidth: "300px"}}
                        onInputChange={(e, value) => {
                          setSelectedCompany(undefined);
                          setCompanyFilter(value);
                        }}
                        onChange={(e, data) => {
                          setSelectedCompany(data);
                          setCompanyFilter(data?.company ?? "");
                        }}
                      />
                    </Grid>
                  </>
                ) : undefined
              }
              <Grid item xs={12}>
                <Typography>External Software</Typography>
                <Select
                  value={selectedExtSoftware}
                  onChange={(e) => {
                    setSelectedExtSoftware(e.target.value);
                  }}
                  variant="filled"
                  displayEmpty
                  fullWidth
                  style={{maxWidth: "200px"}}
                >
                  <MenuItem value={""}>
                    {"No Software"}
                  </MenuItem>
                  {externalSoftwareList.map((software) => {
                    return (
                      <MenuItem key={software.external_software_id} value={software.external_software_id}>
                        {software.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Typography>Affiliate Group</Typography>
                <Select
                  value={selectedAffiliateGroup}
                  onChange={(e) => {
                    setSelectedAffiliateGroup(e.target.value);
                  }}
                  variant="filled"
                  displayEmpty
                  fullWidth
                  style={{maxWidth: "200px"}}
                >
                  <MenuItem value={""}>
                    {"No Group"}
                  </MenuItem>
                  {affiliateGroupList.map((group) => {
                    return (
                      <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                        {group.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="create-site-button"
                  disabled={disabled}
                  onClick={handleSubmit}
                  variant="contained"
                  size="large"
                >
                  Create Site
                </Button>
              </Grid>
            </Grid>
            {showProgress && <ImIcons.ImSpinner9 size={"50"} className="rotate"/>}
          </Paper>
          {renderDialog()}
          {/* <Grid item xs={8}>
          <Paper>
            <TextField
              onChange={handleS3Change}/>
              <Button
                onClick={handleNewS3}
              >
                Create S3 Bucket
              </Button>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <TextField
            onChange={handleEc2Change}/>
              <Button
                onClick={handleNewEc2}
              >
                Create Ec2 Instance
              </Button>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={8}>
          <Paper>
            <Button
              onClick={handleDescribeEc2}
            >
              Describe EC2 Instances
            </Button>
          </Paper>
        </Grid> */}
    </div>
  );
}

export default NewSite;