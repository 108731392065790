import React, { useEffect, useState, useContext } from "react";
import Tooltip from '@material-ui/core/Tooltip';

// MUI
import {
  Typography,
  CircularProgress,
  Button,
  TextField,
  Paper,
  Grid,
  ListItem,
  List,
  ListItemText,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Dialog,
  Select,
  IconButton
} from "@material-ui/core";
import DateFnsUtils from '@date-io/moment';

// Style
import "../../Components/styles/SiteInfo.css";

// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Actions
import {
  listS3Files,
  getS3Files,
  setMailer,
  getB2bEmailRequests,
  getB2bInfo,
  setB2bStatus,
  getSiteEC2InfoBySiteName,
  getSiteInfoBySiteName,
  addB2BConnection,
  getExternalSoftwareList,
  addExternalSoftwareOption,
  setExternalSoftwareForSite,
  setSubscriptionForSite,
  getAffiliateGroupList,
  setAffiliateGroupForSite,
  addAffiliateGroupOption,
  testB2BConnection,
  getContacts,
  getExternalSiteUsers,
  getExternalSiteTemplates
} from "../../Actions";

import { AlertContext } from "../../Context/AlertContextProvider";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Alert } from "@material-ui/lab";
import { Edit, GetApp, Launch } from "@material-ui/icons";
import { MenuItem } from "@mui/material";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { convertSYSDATEToUTC } from "../../Util/utilsFunctions";
import B2BDataManagementDialog from "./B2BDataManagementDialog";
import { ContactListDialog, UpdateCompanyDialog, UpdateContactDialog } from "./SiteInfoDialogs";

function SubscriptionStatus (props) {
  let severity;
  let verbiage;

  switch (props.subscriptionStatus) {
    case 0:
      severity = "success";
      verbiage = "Subscription active";
      break;
    case 1:
      const endDate = new Date(props.subscriptionEndDate?.split(" ")[0]);
      const currentDate = new Date();
      const retentionDate = new Date(endDate);
      retentionDate.setMonth(endDate.getMonth() + 2);
      severity = endDate.getTime() > currentDate.getTime() ? "warning" : "error";

      if (endDate.getTime() > currentDate.getTime()) {
        verbiage = "Subscription ending on " + endDate.toLocaleDateString();
      } else if (endDate.getTime() <= currentDate.getTime() && currentDate.getTime() <= retentionDate.getTime()) {
        verbiage = "Subscription in grace period until " + retentionDate.toLocaleDateString();
      } else {
        verbiage = "Subscription deactivated";
      }
      
      break;
    case 2:
      severity = "warning";
      verbiage = "Subscription paused";
      break;
    default:
      severity = "info";
      verbiage = "No status";
  }

  return (
    <Alert 
      icon={
        <IconButton 
          size="small" 
          onClick={() => props.edit()}
          style={{display: "inline-block", verticalAlign: "top", cursor: "pointer"}}
        >
          <Edit fontSize="inherit"/>
        </IconButton>
      }
      severity={severity}
    >
      {verbiage}
    </Alert>
  )
}

function EC2Status (props) {
  let siteStatus;

  switch (props.data?.status) {
    case "running":
      siteStatus = (
        <Alert severity="success">
          EC2 {props.data?.status}
        </Alert>
      )
      break;
    case "pending":
    case "stopping":
      siteStatus = (
        <Alert severity="warning">
          EC2 {props.data?.status}
        </Alert>
      )
      break;
    case "shutting-down":
      siteStatus = (
        <Alert severity="error">
          EC2 {props.data?.status}
        </Alert>
      )
      break;
    default: 
      if (props.data) {
        siteStatus = (
          <Alert severity="error">
            EC2 {props.data?.status}
          </Alert>
        )
      } else {
        siteStatus = undefined
      }
  }

  return (siteStatus)
}

function AppStatus (props) {
  let appVersion;
  if (props.data?.apiStatus.version === "unknown") {
    appVersion = props.data?.apiStatus.version;
  } else if (props.data?.apiStatus.version) {
    appVersion = `v${props.data?.apiStatus.version}`
  }

  return (
    <Tooltip
      title={
        <div>
          <div>
            <Typography variant="body1">
              RESPONSE TIME: {props.data?.apiStatus.responseTime ?? ''} ms
            </Typography>
          </div>
          <br></br>
          <div>
            <Typography variant="body1">
              START TIME: {props.data?.startTime + " (UTC)"}
            </Typography>
          </div>
        </div>
      }
    >
      {!props.data ? (
          <div></div>
        ) : props.data.apiStatus.version != "unknown" ? (
          <Alert severity="success">
            {`App ${props.data?.apiStatus.applicationStatus ?? ''} (${appVersion})`}
          </Alert>
        ) : (
          <Alert severity="error">
            {`App ${props.data?.apiStatus.applicationStatus}`}
          </Alert>
        )
      }
    </Tooltip>
  )
}

export default function SiteInfo(props) {
  const { site } = useParams();
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const [siteInfo, setSiteInfo] = useState({});
  const [ec2Info, setEc2Info] = useState();
  const [refresh, setRefresh] = useState(true);
  const [fileDownloadKey, setFileDownloadKey] = useState();
  const [fileList, setFileList] = useState([]);
  const [fileFilter, setFileFilter] = useState("");
  const [address, setAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [partnerId, setpartnerId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [b2bPartners, setB2bPartners] = useState([]);
  const [b2bInfo, setB2bInfo] = useState([]);
  const [showPartnerId, setShowPartnerId] = useState();
  const [externalSoftwareList, setExternalSoftwareList] = useState([]);
  const [showExtSoftwareDialog, setShowExtSoftwareDialog] = useState(false);
  const [selectedExtSoftware, setSelectedExtSoftware] = useState("");
  const [newExtSoftwareName, setNewExtSoftwareName] = useState("");
  const [affiliateGroupList, setAffiliateGroupList] = useState([]);
  const [externalSiteUsers, setExternalSiteUsers] = useState([]);
  const [externalSiteUsersFilter, setExternalSiteUsersFilter] = useState([]);
  const [externalSiteTemplates, setExternalSiteTemplates] = useState([]);
  const [externalSiteTemplatesFilter, setExternalSiteTemplatesFilter] = useState([]);
  const [leadContacts, setLeadContacts] = useState([]);
  const [showAffiliateGroupDialog, setShowAffiliateGroupDialog] = useState(false);
  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("");
  const [newAffiliateGroupName, setNewAffiliateGroupName] = useState("");
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(new Date().toISOString().split("T")[0]);
  const [showB2BManageDialog, setShowB2BManageDialog] = useState(false);
  const [showEdiIdTestSpinner, setShowEdiIdTestSpinner] = useState();
  const [showCompanyUpdateDialog, setShowCompanyUpdateDialog] = useState(false);
  const [showUpdateContactDialog, setShowUpdateContactDialog] = useState(false);
  const [showAllContactsDialog, setShowAllContactsDialog] = useState(false);

  useEffect(() => {
    async function getSiteData () {
      let siteId;
      let leadId;

      await getSiteInfoBySiteName(site)
        .then((result) => {
          siteId = result.data.site_id;
          leadId = result.data.lead_id;

          setSiteInfo(result.data);
          setSelectedExtSoftware(result.data.external_software_id ?? "");
          setSelectedAffiliateGroup(result.data.affiliate_group_id ?? "");
          setSelectedSubStatus(result.data.subscription_status ?? "");
          setSubscriptionEndDate(result.data.subscription_end_dttm?.split(" ")[0] ?? new Date().toISOString().split("T")[0]);
        })
        .catch((err) => {
          setSiteInfo({});
          setSelectedExtSoftware("");
          setSelectedAffiliateGroup("");
          setSelectedSubStatus("");
          setSubscriptionEndDate(new Date().toISOString().split("T")[0]);
        });

      await getSiteEC2InfoBySiteName(site)
        .then((result) => {
          setEc2Info(result.data);
        })
        .catch((err) => {
          setEc2Info(undefined);
        });

      await getExternalSoftwareList()
        .then((result) => {
          setExternalSoftwareList(result.data.data);
        })
        .catch((err) => {
          setExternalSoftwareList([]);
        });

      await getAffiliateGroupList()
        .then((result) => {
          setAffiliateGroupList(result.data.data);
        })
        .catch((err) => {
          setAffiliateGroupList([]);
        });

        await getExternalSiteUsers(site)
        .then((result) => {
          setExternalSiteUsers(result.data.data);
        })
        .catch((err) => {
          setExternalSiteUsers([]);
        });

        await getExternalSiteTemplates(site)
        .then((result) => {
          setExternalSiteTemplates(result.data.data);
        })
        .catch((err) => {
          setExternalSiteTemplates([]);
        });

      if (siteId) {
        await getB2bInfo(siteId)
          .then((result) => {
            setB2bInfo(result.data.response);
          })
          .catch((err) => {
            setB2bInfo([]);
          });
      }

      if (leadId) {
        await getContacts(leadId)
          .then((result) => {
            setLeadContacts(result.data.data);
          })
          .catch((err) => {
            setLeadContacts([]);
          });
      }
    }

    if (refresh) {
      getSiteData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!siteInfo.site_id) return;

    setSiteId(siteInfo.site_id);

    listS3Files(siteInfo.site_name)
      .then((result) => {
        setFileList(result.data.fileList.Contents);
      })
      .catch((err) => {
        console.log(err);
      });

    getB2bEmailRequests(siteInfo.site_url)
      .then((result) => {
        setB2bPartners(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [siteInfo.site_id])

  const handleSwitchState = (newValue, partner) => {
    if (newValue === false) {
      newValue = 0;
    } else if (newValue === true) {
      if (!partner.alternate_partner_name || !partner.alternate_port || !partner.alternate_host || !partner.alternate_transfer_type || !partner.alternate_fcb2b_version || !partner.interval_seconds || !partner.user || !partner.pass) {
        setMessage("Please check all required fields for B2B connection")
        setMessageType("error");
        handleAlertOpen();
        return;
      }

      newValue = 1;
    }

    setB2bStatus(newValue, partner.edi_id, partner.site_id)
      .then((result) => {
        getB2bInfo(partner.site_id)
          .then((result) => {
            setB2bInfo(result.data.response);
          })
          .catch((err) => {
            console.log(err)
          });
      })
  }

  const handleGetFile = async (site, file) => {
    setFileDownloadKey(file);
    await getS3Files(site, file)
      .then(result => {
        setFileDownloadKey(undefined);
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(
          new Blob([new Uint8Array(result.data.Body.data)], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
        );
        a.download = String(file.split("/")[1].split("_").slice(1)).replace(/,/g, "_");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleAddress = (e) => {
    setAddress(e);
  };

  const handleSubject = (e) => {
    setSubject(e);
  }

  const handleBody = (e) => {
    setBody(e);
  }

  const handleSendB2BRequest = () => {
    setMailer(address, subject, body, undefined, partnerId, siteId)
      .then((result) => {
        setMessage("B2B request sent successfully");
        setMessageType("success");
        handleAlertOpen();
        resetVars();
        setRefresh(true);
      })
      .catch((err) => {
        setMessage("Error sending B2B request")
        setMessageType("error");
        handleAlertOpen();
        console.log(err);
      });
  };

  const handleAddB2BConnection = () => {
    addB2BConnection(partnerId, siteId)
      .then((result) => {
        setMessage("Successfully added B2B Connection")
        setMessageType("success");
        handleAlertOpen();
        resetVars();
        setRefresh(true);
      })
      .catch((err) => {
        setMessage("Error adding B2B connection")
        setMessageType("error");
        handleAlertOpen();
        console.log(err);
      });
  };

  const resetVars = () => {
    setAddress("");
    setSubject("");
    setBody("");
    setpartnerId("");
    setSiteId("");
  };

  const handleB2bPartnerSelect = (e) => {
    if (e.target.value === "") {
      resetVars();
      return;
    }

    setpartnerId(e.target.value);

    b2bPartners.filter(partner => partner.partner_id == e.target.value).map(filteredPartner => (
      setSubject(filteredPartner.email_desc),
      setAddress(filteredPartner.contact_email),
      setBody(filteredPartner.body),
      setpartnerId(filteredPartner.partner_id),
      setSiteId(filteredPartner.site_id)
    ))
  };

  const handleExtSoftwareSubmit = (siteId, externalSoftwareId, name) => {
    if (externalSoftwareId != -1) {
      const newId = !externalSoftwareId ? null : externalSoftwareId;

      setExternalSoftwareForSite(siteId, newId).then(result => {
        setMessage("Successfully updated external software option for site");
        setMessageType("success");
        handleAlertOpen();
        setShowExtSoftwareDialog(false);
        setRefresh(true);
      }).catch(err => {
        setMessage("Error updating external software option for site");
        setMessageType("error");
        handleAlertOpen();
      });
  
      return;
    }

    if (name && externalSoftwareList.find(s => s.name.toLowerCase() == name.toLowerCase())) {
      setMessage("Duplicate option found");
      setMessageType("error");
      handleAlertOpen();
      return;
    }

    addExternalSoftwareOption(name).then(result => {
      setMessage("Successfully added external software option");
      setMessageType("success");
      handleAlertOpen();

      setExternalSoftwareForSite(siteId, result.data.data.insertId).then(result => {
        setMessage("Successfully updated external software option for site");
        setMessageType("success");
        handleAlertOpen();
        setNewExtSoftwareName("");
        setShowExtSoftwareDialog(false);
        setRefresh(true);
      }).catch(err => {
        setMessage("Error updating external software option for site");
        setMessageType("error");
        handleAlertOpen();
      })
    }).catch(err => {
      setMessage("Error adding external software option");
      setMessageType("error");
      handleAlertOpen();
    })
  }

  const handleAffiliateGroupSubmit = (siteId, affiliateGroupId, name) => {
    if (affiliateGroupId != -1) {
      const newId = !affiliateGroupId ? null : affiliateGroupId;

      setAffiliateGroupForSite(siteId, newId).then(result => {
        setMessage("Successfully updated affiliate group option for site");
        setMessageType("success");
        handleAlertOpen();
        setShowAffiliateGroupDialog(false);
        setRefresh(true);
      }).catch(err => {
        setMessage("Error updating affiliate group option for site");
        setMessageType("error");
        handleAlertOpen();
      });
  
      return;
    }

    if (name && affiliateGroupList.find(s => s.name.toLowerCase() == name.toLowerCase())) {
      setMessage("Duplicate option found");
      setMessageType("error");
      handleAlertOpen();
      return;
    }

    addAffiliateGroupOption(name).then(result => {
      setMessage("Successfully added affiliate group option");
      setMessageType("success");
      handleAlertOpen();

      setAffiliateGroupForSite(siteId, result.data.data.insertId).then(result => {
        setMessage("Successfully updated affiliate group option for site");
        setMessageType("success");
        handleAlertOpen();
        setNewAffiliateGroupName("");
        setShowAffiliateGroupDialog(false);
        setRefresh(true);
      }).catch(err => {
        setMessage("Error updating affiliate group option for site");
        setMessageType("error");
        handleAlertOpen();
      })
    }).catch(err => {
      setMessage("Error adding affiliate group option");
      setMessageType("error");
      handleAlertOpen();
    })
  }

  const handleSubscriptionSubmit = (siteId, subscriptionStatus, subscriptionEndDate) => {
    const newEndDate = subscriptionStatus != 1 ? null : subscriptionEndDate;
    const newStatus = subscriptionStatus == "" ? null : subscriptionStatus;

    setSubscriptionForSite(siteId, newStatus, newEndDate).then(result => {
      setMessage("Successfully updated subscription for site");
      setMessageType("success");
      handleAlertOpen();
      setShowSubscriptionDialog(false);
      setRefresh(true);
    }).catch(err => {
      setMessage("Error updating subscription for site");
      setMessageType("error");
      handleAlertOpen();
      setShowSubscriptionDialog(false);
    })
  }

  const renderB2bDropdown = () => {
    return (
      <Select
        onChange={handleB2bPartnerSelect}
        value={partnerId}
        displayEmpty
        variant="filled"
        style={{marginBottom: "10px", width: "75%", textAlign: "left", minWidth: "200px"}}
      >
        <MenuItem key="default" value="">Please Select a B2B Vendor</MenuItem>
        {b2bPartners.map((p, index) => {
          return (
            <MenuItem disabled={p.partner_id === null} key={p.partner_id} value={p.partner_id}>
              {p.name}
            </MenuItem>
          );
        })}
      </Select>
    )
  };

  const testConnection = (partner) => {
    if (!partner.user || !partner.pass || !partner.alternate_host || !partner?.alternate_port) {
      let missingData = "";
      missingData += !partner.user ? "username" : "";
      missingData += !partner.pass ? ", password" : "";
      missingData += !partner.alternate_host ? ", host" : "";
      missingData += !partner.alternate_port ? ", port" : "";

      setMessage(`Can't test due to missing data [${missingData.replace(/^,\s/, "")}]`)
      setMessageType("error");
      handleAlertOpen();
      setShowEdiIdTestSpinner(undefined);
      return;
    }

    setShowEdiIdTestSpinner(partner.edi_id);
    testB2BConnection(partner.edi_id).then(result => {
      setMessage(`${partner.name} test successful!`)
      setMessageType("success");
      handleAlertOpen();
      setShowEdiIdTestSpinner(undefined);
    }).catch(err => {
      setMessage(`${partner.name} test failed`)
      setMessageType("error");
      handleAlertOpen();
      setShowEdiIdTestSpinner(undefined);
    })
  }

  const renderFiles = (files, filter) => {
    const filteredFiles = files?.filter((file) =>
      file.Key.toLowerCase().includes(filter)
    );

    filteredFiles?.sort((a, b) => {
      return new Date(a.LastModified).getTime() -
        new Date(b.LastModified).getTime()
    }).reverse();

    return filteredFiles?.length ? (
      filteredFiles.map((file, index) => {
        let fileSize = file.Size;
        let sizeType = "B";

        if (fileSize * .000000001 > 1) {
          fileSize = fileSize * .000000001;
          sizeType = "GB";
        } else if (fileSize * .000001 > 1) {
          fileSize = fileSize * .000001;
          sizeType = "MB";
        } else if (fileSize * .001 > 1) {
          fileSize = fileSize * .001;
          sizeType = "KB";
        }

        return (
          <ListItem 
            key={index} 
            onClick={() => handleGetFile(siteInfo.site_name, file.Key)} 
            button
            style={{borderBottom: "1px solid #838383"}}
          >
            <ListItemIcon>
              {fileDownloadKey === file.Key ? <CircularProgress size={24}/> : <GetApp/>}
            </ListItemIcon>
            <ListItemText
              primary={file.Key.split("manual/")[1]}
              secondary={
                <React.Fragment>
                  {new Date(file.LastModified).toLocaleDateString()} - {fileSize.toFixed(2) + ' ' + sizeType}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })
    ) : (
      <Typography style={{margin: "20px"}}>No Manual Upload Files Available</Typography>
    )
  };

  const renderExternalUsers = (users, userFilter) => {
    const filteredUsers = users?.filter((user) =>
      user.name.toLowerCase().includes(userFilter)
    );

    filteredUsers?.sort((a, b) => a.name.localeCompare(b.name));

    return filteredUsers?.length ? (
      filteredUsers.map((user, index) => {
        return (
          <ListItem 
            key={index} 
            style={{borderBottom: "1px solid #838383"}}
          >
            <ListItemText
              primary={user.name}
              secondary={
                <React.Fragment>
                  {user.accessType + (user.last_login_dttm ? "\t(Last Login: " + user.last_login_dttm + ")" : '')}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })
    ) : (
      <Typography style={{margin: "20px"}}>No Users Available</Typography>
    )
  };

  const renderExternalTemplates = (templates, templateFilter) => {
    const filteredTemplates = templates?.filter((template) =>
      template.distributor_name.toLowerCase().includes(templateFilter)
    );

    filteredTemplates?.sort((a, b) => a.distributor_name.localeCompare(b.distributor_name));

    return filteredTemplates?.length ? (
      filteredTemplates.map((template, index) => {
        return (
          <ListItem 
            key={index} 
            style={{borderBottom: "1px solid #838383"}}
          >
            <ListItemText
              primary={template.distributor_name}
            />
          </ListItem>
        );
      })
    ) : (
      <Typography style={{margin: "20px"}}>No Templates Available</Typography>
    )
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className="siteinfo__paper">
          <Paper elevation={2} style={{margin: "0px auto 20px", padding: "0px 0px 20px"}}>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Site Info
              </Typography>
            </div>

            <div style={{padding: "15px 20px 10px"}}>
              <Typography variant="h5"><b>{siteInfo?.company_name}</b></Typography>
              { siteInfo?.company_website ? (
                  <Typography>
                    <a href={`${siteInfo?.company_website}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
                      <Launch style={{display: "inline-block", marginRight: "3px", verticalAlign: "middle"}}></Launch> {siteInfo?.company_website}
                    </a>
                  </Typography>
                ) : undefined
              }
              <Typography style={{marginTop: "10px"}}>{siteInfo?.company_address}</Typography>
              <Typography>{siteInfo?.company_city}, {siteInfo?.company_state} {siteInfo?.company_zip}</Typography>
              { siteInfo?.company_country ? (
                  <Typography>{siteInfo?.company_country}</Typography>
                ) : undefined
              }
              <Button variant="contained" onClick={() => setShowCompanyUpdateDialog(true)} style={{margin: "20px 0px 10px"}}>
                Edit Company
              </Button>
            </div>

            <div style={{padding: "15px 20px"}}>
              <Typography variant="h6" style={{marginBottom: "10px"}}><b>Site Statuses</b></Typography>
              <Grid container spacing={2}>
                { ec2Info ? (
                  <>
                    <Grid item xs={12} sm={12} lg={6}>
                      <EC2Status data={ec2Info} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <AppStatus data={ec2Info} />
                    </Grid>
                  </>
                  ) : undefined 
                }
                <Grid item xs={12} sm={12} lg={6}>
                  <SubscriptionStatus 
                    subscriptionStatus={siteInfo.subscription_status}
                    subscriptionEndDate={siteInfo.subscription_end_dttm}
                    edit={() => setShowSubscriptionDialog(true)}
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{padding: "15px 20px 10px"}}>
              <Typography variant="h6" style={{marginBottom: "10px"}}><b>Additional Site Info</b></Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography><b>Created Date</b></Typography>
                  <Typography>{new Date(convertSYSDATEToUTC(siteInfo.created_dttm)).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography><b>Database</b></Typography>
                  <Typography>{siteInfo.db_instance}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <b>External Software</b>
                    <IconButton 
                      size="small" 
                      onClick={() => setShowExtSoftwareDialog(true)}
                      style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                    >
                      <Edit fontSize="inherit"/>
                    </IconButton>
                  </Typography>
                  <Typography>
                    {externalSoftwareList?.find(s => s.external_software_id === siteInfo.external_software_id)?.name ?? "None Selected"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <b>Affiliate Group</b>
                    <IconButton 
                      size="small" 
                      onClick={() => setShowAffiliateGroupDialog(true)}
                      style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                    >
                      <Edit fontSize="inherit"/>
                    </IconButton>
                  </Typography>
                  <Typography>
                    {affiliateGroupList?.find(g => g.affiliate_group_id === siteInfo.affiliate_group_id)?.name ?? "None Selected"}
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid container style={{padding: "15px 20px"}}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{marginBottom: "10px"}}><b>Primary Contact</b></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography><b>{siteInfo.contact_name}</b></Typography>
                <Typography>{siteInfo.contact_phone}</Typography>
                <a href={`mailto:${siteInfo.contact_email}`}><Typography>{siteInfo.contact_email}</Typography></a>
              </Grid>
              <Grid item xs={12} style={{paddingTop: "15px"}}>
                { siteInfo?.contact_id ? (
                    <Button
                      variant="contained"
                      onClick={() => setShowUpdateContactDialog(true)}
                      style={{marginRight: "20px"}}
                    >
                      Edit Contact
                    </Button>
                  ) : undefined
                }
                { leadContacts?.length ? (
                    <Button
                      variant="contained"
                      onClick={() => setShowAllContactsDialog(true)}
                      style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                    >
                      View All Contacts
                    </Button>
                  ) : undefined
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <a href={"https://" + siteInfo.site_name} target="_blank">
              <div className="siteinfo__header">
                <Typography variant="h4">
                  Live Site View <Launch fontSize="large" style={{marginLeft: "3px", verticalAlign: "middle"}}></Launch>
                </Typography>
              </div>
            </a>
            <div style={{height: "525px"}}>
              {siteInfo?.site_name ? (
                  <iframe title={site + " Site View"} style={{height: "100%", width: "100%", overflow: "scroll", border: "0px"}} src={"https://" + siteInfo.site_name}></iframe>
                ) : undefined
              }
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className="siteinfo__header">
              <Typography variant="h4">
                B2B Partner Contact Form
              </Typography>
            </div>
            <div style={{ padding: 20, textAlign: "center" }}>
              <Grid container>
                <Grid item xs={12} >
                  {renderB2bDropdown()}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="address"
                    label="Address"
                    type="email"
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleAddress(e.target.value)}
                    value={address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="subject"
                    label="Subject"
                    type="text"
                    variant="filled"
                    fullWidth
                    onChange={(e) => handleSubject(e.target.value)}
                    value={subject}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="body"
                    label="Body"
                    type="text"
                    variant="filled"
                    multiline
                    fullWidth
                    maxRows={15}
                    minRows={15}
                    onChange={(e) => handleBody(e.target.value)}
                    value={body}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleAddB2BConnection} disabled={!partnerId} style={{margin: "20px 10px"}} variant={'contained'}>Add Connection</Button>
                  <Button onClick={handleSendB2BRequest} disabled={!partnerId} style={{margin: "20px 10px", backgroundColor: "#142e3e", color: "#28c4fc"}} variant={'contained'}>Send Request and Add</Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className="siteinfo__header">
              <Typography variant="h4">
                B2B Information
              </Typography>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="b2b-table-cell" style={{width: "40%", padding: "10px"}}>
                    <Typography variant="h6"><b>Name</b></Typography>
                  </TableCell>
                  <TableCell className="b2b-table-cell" style={{width: "60%", padding: "10px 5px"}}>
                    <Typography variant="h6"><b>Functions</b></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div className="b2b-paper" style={{maxHeight: "490px"}}>
              {b2bInfo.sort((a, b) => {
                if (a.name === b.name && a.brand && b.brand) {
                  return a.brand.localeCompare(b.brand);
                }
                return a.name.localeCompare(b.name);
              }).map((partner, index) => {
                const isRunning = partner.active_ind === 1;
                return (
                  <div key={index}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="b2b-table-cell" style={{width: "40%", padding: "10px"}}>
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              <b>{partner.name}</b>
                            </Typography>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              {partner.brand}
                            </Typography>
                          </TableCell>
                          <TableCell className="b2b-table-cell" style={{width: "60%", padding: "5px"}}>
                            <Button
                              onClick={() => {
                                setShowPartnerId(partner.edi_id);
                                setShowB2BManageDialog(true);
                              }}
                              variant="contained"
                              style={{margin: "7px"}} 
                            >
                              Manage 
                            </Button>
                            <Button
                              onClick={() => testConnection(partner)}
                              variant="contained"
                              style={{margin: "7px", width: "70px", backgroundColor: "#142e3e", color: "#28c4fc"}} 
                            >
                              {showEdiIdTestSpinner === partner.edi_id ? <CircularProgress size="24.5px" color="inherit"/> : "Test"} 
                            </Button>
                            <Button 
                              variant="contained" 
                              onClick={() => handleSwitchState(!isRunning, partner)}
                              style={{backgroundColor: isRunning ? "#f44336" : "#4caf50", color: "#FFFFFF", width: "70px", margin: "7px"}}
                            >
                              {isRunning ? "stop" : "start"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )
              })}
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Manual File Uploads
              </Typography>
            </div>
            <div style={{padding: "20px", borderBottom: "1px solid #838383"}}>
              <Typography>File Name Search</Typography>
              <TextField
                type="text"
                label="Search by file name"
                value={fileFilter}
                onChange={(e) => setFileFilter(e.target.value.toLowerCase())}
                variant="filled"
                style={{minWidth: "250px", width: "30%", maxWidth: "350px"}}
              />
            </div>
            <List style={{maxHeight: "600px", overflowY: "scroll", paddingTop: "0px", paddingBottom: "0px"}}>{renderFiles(fileList, fileFilter)}</List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Site Templates
              </Typography>
            </div>
            <div style={{padding: "20px", borderBottom: "1px solid #838383"}}>
              <Typography>Template Search</Typography>
              <TextField
                type="text"
                label="Search for template"
                value={externalSiteTemplatesFilter}
                onChange={(e) => setExternalSiteTemplatesFilter(e.target.value.toLowerCase())}
                variant="filled"
                style={{minWidth: "250px", width: "30%", maxWidth: "350px"}}
              />
            </div>
            <List style={{maxHeight: "600px", overflowY: "scroll", paddingTop: "0px", paddingBottom: "0px"}}>{renderExternalTemplates(externalSiteTemplates, externalSiteTemplatesFilter)}</List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Site Users
              </Typography>
            </div>
            <div style={{padding: "20px", borderBottom: "1px solid #838383"}}>
              <Typography>User Search</Typography>
              <TextField
                type="text"
                label="Search for user"
                value={externalSiteUsersFilter}
                onChange={(e) => setExternalSiteUsersFilter(e.target.value.toLowerCase())}
                variant="filled"
                style={{minWidth: "250px", width: "30%", maxWidth: "350px"}}
              />
            </div>
            <List style={{maxHeight: "600px", overflowY: "scroll", paddingTop: "0px", paddingBottom: "0px"}}>{renderExternalUsers(externalSiteUsers, externalSiteUsersFilter)}</List>
          </Paper>
        </Grid>
      </Grid>

      <Dialog 
        open={showExtSoftwareDialog} 
        onClose={() => {
          setSelectedExtSoftware(siteInfo.external_software_id ?? "");
          setShowExtSoftwareDialog(false);
        }}
      >
        <Paper style={{padding: "25px 20px 15px"}}>
          <Typography variant="h6" style={{marginBottom: "15px"}}>Please select the external software being used by the site</Typography>
          <Grid container spacing={3} style={{marginBottom: "25px"}}>
            <Grid item xs={12} sm={6}>
              <Typography>External Software</Typography>
              <Select
                value={selectedExtSoftware}
                onChange={(e) => {
                  setSelectedExtSoftware(e.target.value);
                  setNewExtSoftwareName("");
                }}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value={""}>
                  {"No Software"}
                </MenuItem>
                <MenuItem value={"-1"}>
                  {"New Option"}
                </MenuItem>
                {externalSoftwareList.map((software) => {
                  return (
                    <MenuItem key={software.external_software_id} value={software.external_software_id}>
                      {software.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            { selectedExtSoftware == -1 ? (
                <Grid item xs={12} sm={6}>
                  <Typography>New Option</Typography>
                  <TextField
                    type="text"
                    label="Add new option"
                    value={newExtSoftwareName}
                    onChange={(e) => setNewExtSoftwareName(e.target.value)}
                    variant="filled"
                    fullWidth
                  />
                </Grid>
              ) : undefined
            }
          </Grid>
          <div style={{textAlign: "right"}}>
            <Button
              onClick={() => {
                setSelectedExtSoftware(siteInfo.external_software_id ?? "");
                setShowExtSoftwareDialog(false);
              }}
              variant="contained"
              style={{margin: "10px 0px 10px 20px"}}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleExtSoftwareSubmit(siteInfo.site_id, selectedExtSoftware, newExtSoftwareName)}
              variant="contained"
              style={{margin: "10px 0px 10px 20px", backgroundColor: "#4caf50", color: "#FFFFFF"}}
              disabled={selectedExtSoftware == -1 && !newExtSoftwareName}
            >
              Update
            </Button>
          </div>
        </Paper>
      </Dialog>
      <Dialog 
        open={showAffiliateGroupDialog} 
        onClose={() => {
          setSelectedAffiliateGroup(siteInfo.affiliate_group_id ?? "");
          setShowAffiliateGroupDialog(false);
        }}
      >
        <Paper style={{padding: "25px 20px 15px"}}>
          <Typography variant="h6" style={{marginBottom: "15px"}}>Please select the affiliate group that the site is a part of</Typography>
          <Grid container spacing={3} style={{marginBottom: "25px"}}>
            <Grid item xs={12} sm={6}>
              <Typography>Affiliate Group</Typography>
              <Select
                value={selectedAffiliateGroup}
                onChange={(e) => {
                  setSelectedAffiliateGroup(e.target.value);
                  setNewAffiliateGroupName("");
                }}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value={""}>
                  {"No Group"}
                </MenuItem>
                <MenuItem value={"-1"}>
                  {"New Option"}
                </MenuItem>
                {affiliateGroupList.map((group) => {
                  return (
                    <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                      {group.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            { selectedAffiliateGroup == -1 ? (
                <Grid item xs={12} sm={6}>
                  <Typography>New Option</Typography>
                  <TextField
                    type="text"
                    label="Add new option"
                    value={newAffiliateGroupName}
                    onChange={(e) => setNewAffiliateGroupName(e.target.value)}
                    variant="filled"
                    fullWidth
                  />
                </Grid>
              ) : undefined
            }
          </Grid>
          <div style={{textAlign: "right"}}>
            <Button
              onClick={() => {
                setSelectedAffiliateGroup(siteInfo.affiliate_group_id ?? "");
                setShowAffiliateGroupDialog(false);
              }}
              variant="contained"
              style={{margin: "10px 0px 10px 20px"}}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleAffiliateGroupSubmit(siteInfo.site_id, selectedAffiliateGroup, newAffiliateGroupName)}
              variant="contained"
              style={{margin: "10px 0px 10px 20px", backgroundColor: "#4caf50", color: "#FFFFFF"}}
              disabled={selectedAffiliateGroup == -1 && !newAffiliateGroupName}
            >
              Update
            </Button>
          </div>
        </Paper>
      </Dialog>
      <Dialog 
        open={showSubscriptionDialog} 
        onClose={() => {
          setSelectedSubStatus(siteInfo.subscription_status ?? "");
          setSubscriptionEndDate(siteInfo.subscription_end_dttm?.split(" ")[0] ?? new Date().toISOString().split("T")[0]);
          setShowSubscriptionDialog(false);
        }}
      >
        <Paper style={{padding: "25px 20px 15px"}}>
          <Typography variant="h6" style={{marginBottom: "15px"}}>Please set the subscription status and end date below</Typography>
          <Grid container spacing={3} style={{marginBottom: "25px"}}>
            <Grid item xs={12} sm={6}>
              <Typography>Subscription Status</Typography>
              <Select
                value={selectedSubStatus}
                onChange={(e) => {
                  setSelectedSubStatus(e.target.value);
                  setSubscriptionEndDate(new Date().toISOString().split("T")[0]);
                }}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value={""}>
                  No Status
                </MenuItem>
                <MenuItem value={"0"}>
                  Active
                </MenuItem>
                <MenuItem value={"1"}>
                  Deactivated
                </MenuItem>
                <MenuItem value={"2"}>
                  Paused
                </MenuItem>
              </Select>
            </Grid>
            { selectedSubStatus == 1 ? (
                <Grid item xs={12} sm={6}>
                  <Typography>Subscription End Date</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-DD"
                      value={subscriptionEndDate}
                      onChange={(e) => setSubscriptionEndDate(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change subscription end date',
                      }}
                      inputVariant="filled"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : undefined
            }
          </Grid>
          <div style={{textAlign: "right"}}>
            <Button
              onClick={() => {
                setSelectedSubStatus(siteInfo.subscription_status ?? "");
                setSubscriptionEndDate(siteInfo.subscription_end_dttm?.split(" ")[0] ?? new Date().toISOString().split("T")[0]);
                setShowSubscriptionDialog(false);
              }}
              variant="contained"
              style={{margin: "10px 0px 10px 20px"}}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubscriptionSubmit(siteInfo.site_id, selectedSubStatus, subscriptionEndDate)}
              variant="contained"
              style={{margin: "10px 0px 10px 20px", backgroundColor: "#4caf50", color: "#FFFFFF"}}
              disabled={selectedSubStatus === 1 && !subscriptionEndDate}
            >
              Update
            </Button>
          </div>
        </Paper>
      </Dialog>
      <UpdateCompanyDialog 
        isOpen={showCompanyUpdateDialog}
        companyId={siteInfo.company_id}
        company={siteInfo.company_name}
        address={siteInfo.company_address}
        city={siteInfo.company_city}
        state={siteInfo.company_state}
        zip={siteInfo.company_zip}
        country={siteInfo.company_country}
        website={siteInfo.company_website}
        onClose={() => setShowCompanyUpdateDialog(false)}
        refreshData={() => setRefresh(true)}
      />
      <UpdateContactDialog
        isOpen={showUpdateContactDialog} 
        onClose={() => setShowUpdateContactDialog(false)}  
        contactId={siteInfo.contact_id}
        name={siteInfo.contact_name}
        phone={siteInfo.contact_phone}
        email={siteInfo.contact_email}
        refreshData={() => setRefresh(true)}
      />
      <ContactListDialog 
        isOpen={showAllContactsDialog} 
        onClose={() => setShowAllContactsDialog(false)}  
        siteId={siteInfo.site_id}
        contacts={leadContacts}
        refreshData={() => setRefresh(true)}
      />
      <B2BDataManagementDialog 
        isOpen={showB2BManageDialog}
        onClose={() => {
          setShowB2BManageDialog(false);
          setShowPartnerId(undefined);
        }}
        partner={b2bInfo.find(partner => partner.edi_id === showPartnerId)}
        refresh={() => setRefresh(true)}
      />
    </div>
  )
};